import React from "react";
import Seo from "./../../components/Seo";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const query = graphql`
  {
    fileName: file(relativePath: { eq: "books.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMdx(sort: { fields: frontmatter___author, order: DESC }) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            slug
            date
            author
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;

const Poems = () => {
  const { fileName, allMdx } = useStaticQuery(query);
  const poems = allMdx.edges;
  // const image = fileName.childImageSharp.fluid.src;
  // console.log(image);
  return (
    <section className="text-gray-600 body-font">
      <Seo title="Poems" />
      <div className="container flex flex-wrap px-5 py-24 mx-auto">
        <div className="w-full mb-10 overflow-hidden rounded-lg lg:w-1/2 lg:mb-0">
          {/* <GatsbyImage alt="books" image={image} /> */}
          {/* <img
            alt="feature"
            className="object-cover object-center w-full h-full"
            src="https://dummyimage.com/460x500"
          /> */}
        </div>
        {poems &&
          poems.map(
            ({
              node: {
                excerpt,
                frontmatter: {
                  author,
                  date,
                  slug,
                  title,
                  image: {
                    childImageSharp: { gatsbyImageData },
                  },
                },
              },
            }) => (
              <div
                key={`${author}-${title}-${date}`}
                className="flex flex-col flex-wrap -mb-10 text-center lg:py-6 lg:w-1/2 lg:pl-12 lg:text-left"
              >
                <div className="flex flex-col items-center mb-10 lg:items-start">
                  <div className="inline-flex items-center justify-center w-12 h-12 mb-5 text-indigo-500 bg-indigo-100 rounded-full">
                    <GatsbyImage
                      image={getImage(gatsbyImageData)}
                      alt="Blog Post"
                    />
                  </div>
                  <div className="flex-grow">
                    <h2 className="mb-3 text-lg font-medium text-gray-900 title-font">
                      <span>"{title}"</span> by {author}
                    </h2>
                    <p className="text-base leading-relaxed">{excerpt}</p>
                    <a
                      className="inline-flex items-center mt-3 text-indigo-500"
                      href={`/poems/${slug}`}
                    >
                      Read
                      <svg
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                      >
                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            ),
          )}
      </div>
    </section>
  );
};

export default Poems;
